import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import SubHeader from "../components/subheader";

const ContactPage = () => (
  <Layout>
    <SEO title="Contact" />
    <SubHeader
      title="Contact"
      description="How we can help with your business?"
      link="contact"
    />
    <div className="container py-5">
      <div className="row">
        <div className="col-md-12 col-lg-8 offset-lg-2">
          <div className="text-center">
            <form
              action="https://forms.pandabridge.co.uk/92eb1893-5dbb-44b2-b578-08d7254fbe2d"
              method="POST"
              className="text-left pt-3"
            >
              <div class="row">
                <div class="col-md-6">
                  <div className="form-group">
                    <label for="Name">Name</label>
                    <input
                      type="text"
                      class="form-control"
                      id="Name"
                      name="Name"
                      aria-describedby="nameHelp"
                      placeholder="Enter your full name"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div className="form-group">
                    <label for="Email">Email</label>
                    <input
                      type="email"
                      class="form-control"
                      id="Email"
                      name="Email"
                      aria-describedby="emailHelp"
                      placeholder="Enter your email"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div className="form-group">
                    <label for="Company">Company Name (optional)</label>
                    <input
                      type="text"
                      class="form-control"
                      id="Company"
                      name="Company"
                      aria-describedby="companyNameHelp"
                      placeholder="Enter company name"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div className="form-group">
                    <label for="Budget">Budget</label>
                    <select
                      class="form-control"
                      id="Budget"
                      name="Budget"
                      aria-describedby="budgetHelp"
                    >
                      <option value="" disabled selected>
                        Please Select
                      </option>
                      <option value="<10k">&#60; &pound;10,000</option>
                      <option value="10-25k">&pound;10,000 - £25,000</option>
                      <option value="25-50k">&pound;25,000 - £50,000</option>
                      <option value="50-100k">&pound;50,000 - £100,000</option>
                      <option value=">100k">&#62; &pound;100,000</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label for="Enquiry">Enquiry</label>
                <textarea
                  class="form-control"
                  rows="5"
                  id="Enquiry"
                  name="Enquiry"
                  aria-describedby="enquiryHelp"
                  placeholder="Enter your enquiry"
                />
              </div>
              <button type="submit" class="btn btn-primary">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default ContactPage;
